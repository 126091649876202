<template>
  <el-dialog title="原因" :visible.sync="showNotUsed" width="504px" class="dialog-vertical">
    <el-input type="textarea" :rows="5" placeholder="请输入不采用理由(字符不能超过60个)" resize="none" maxlength="60"
      v-model="no_used_reason">
    </el-input>
    <template #footer>
      <el-button size="small" @click="closeModal">取消</el-button>
      <el-button size="small" type="primary" @click="handlePostReason" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { rejectPostArticle } from "../../api/release-list";
export default {
  name: "NotUsed",
  props: ["showNotUsed", "id"],
  data() {
    return {
      no_used_reason: "",
      saveLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeNotUsed");
    },
    handlePostReason() {
      let data = {
        id: this.id,
        reason: this.no_used_reason,
      };
      this.saveLoading = true;
      rejectPostArticle(data)
        .then((res) => {
          this.no_used_reason = "";
          this.closeModal();
          this.saveLoading = false;
          this.$emit("updateList");
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.dialog-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
</style>
