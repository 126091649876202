<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="45px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择文章类型">
            <el-option v-for="(option,index) in type_option" :key="index" :label="option.label"
              :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择文章类型">
            <el-option label="全部" :value="0"></el-option>
            <el-option v-for="(option,index) in category_option" :key="index" :label="option.name"
              :value="option.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="内容：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="作者：" prop="author_name" v-if="form.tab != 'topped'">
          <!--          <el-select v-model="form.nickname" placeholder="请输入作者名称">-->
          <!--            <el-option @click.native="Author(option.user_id,option.user_type)"-->
          <!--              v-for="(option,index) in authorsList" :key="index" :label="option.nickname"-->
          <!--              :value="option.user_id"></el-option>-->
          <!--          </el-select>-->
          <el-input v-model="form.author_name" placeholder="请输入内容"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">

        <DatePeriodPicker label="时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
  import {allClassifyData} from "../../api/classify-list";

  export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      type_option: [
        { label: "全部", value: -1 },
        { label: "纯文本", value: 0 },
        { label: "图文", value: 1 },
        { label: "大图", value: 2 },
        { label: "视频", value: 3 },
      ],
      category_option: [],
      // authorsList: [],
    };
  },
  methods: {
    Author(e, i) {
      this.form.user_id = e;
      this.form.user_type = i;
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    allClassifyData()
      .then((res) => {
        this.category_option = res.data;
      })
      .catch(() => {});

    // getAuthorsList()
    //   .then((res) => {
    //     console.log(res);
    //     this.authorsList = res.data;
    //   })
    //   .catch(() => {});
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    pid: {
      get() {
        return this.form.pid == -2 ? "" : this.form.pid;
      },
      set(val) {
        this.form.pid = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
