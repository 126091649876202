<template>
  <el-dialog title="设置时间" :visible="showSetTime" width="504px" class="dialog-vertical" @close="closeModal">
    <el-form class="small-form" label-width="85px">
      <el-form-item label="">
        <el-date-picker size="medium" v-model="postTime" type="datetime" value-format="timestamp"
                        placeholder="选择日期时间" time-arrow-control style="width: 85%;">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeModal">取消</el-button>
      <el-button size="small" type="primary" @click="handleSetPostTime" :loading="saveLoading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { setTimePostArticle } from "../../api/release-list";
export default {
  name: "SetTimePost",
  props: ["showSetTime", "idList"],
  data() {
    return {
      postTime: "",
      saveLoading: false,
    };
  },
  methods: {
    handleSetPostTime() {
      let timestamp = Date.parse(new Date());
      if (timestamp > this.postTime) {
        this.$message({
          message: "请选择大于当前的时间",
          type: "warning",
        });
        return;
      }
      if (typeof this.idList === "number") {
        this.$emit("setTimePost", this.postTime / 1000);
      } else {
        let data = {
          id: this.idList,
          post_time: this.postTime / 1000,
        };
        this.saveLoading = true;
        setTimePostArticle(data)
          .then((res) => {
            this.$message({
              message: "定时发布成功",
              type: "success",
            });
            this.postTime = "";
            this.closeModal();
            this.$emit("updateList");
            this.saveLoading = false;
          })
          .catch(() => {
            this.saveLoading = false;
          });
      }
    },
    closeModal() {
      this.$emit("closeSetPostTime");
    },
  },
};
</script>

<style scoped>
.dialog-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
</style>
