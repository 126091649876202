import api from "@/base/utils/request";

// 获取发布列表
export const releaseList = data => {
    return api({
        url: "/admin/portal/article/postArticles",
        method: "post",
        data
    });
};
// 获取发布tab栏
export const releaseTab = data => {
    return api({
        url: "/admin/portal/article/tabs",
        method: "post",
        data
    });
};
//文章发布
export const postArticle = data => {
    return api({
        url: "/admin/portal/article/post",
        method: "post",
        data
    });
};
//文章定时发布
export const setTimePostArticle = data => {
    return api({
        url: "/admin/portal/article/scheduledPost",
        method: "post",
        data
    });
};
//文章不采用
export const rejectPostArticle = data => {
    return api({
        url: "/admin/portal/article/rejectPost",
        method: "post",
        data
    });
};