<template>
  <div class="list-page">
    <ReleaseScreen :filterForm="releaseForm" :uploadFilter="callbackForm"></ReleaseScreen>
    <list-tabs v-model="releaseForm.tab" :isLoading="show_loading" :tabs="tabArr"
      @tab-click="handleChangeTab">
    </list-tabs>
    <div class="list-content" v-loading="show_loading">
      <div class="list-btn" v-if="releaseForm.tab ==='posting'">
        <el-button size="small" type="primary" @click="handleRelease">发布</el-button>
        <el-button size="small" @click="handleShowSetTime">定时发布</el-button>
      </div>
      <el-table style="width: 100%" class="thead-light" stripe :data="release_list" @sort-change="sortChange"
        @selection-change="handleSelection">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column show-overflow-tooltip v-for="(item,index) in releaseTable" :prop="item.prop"
          :label="item.label" :min-width="item.min_width" :key="index" :sortable="item.sortable">
          <template slot-scope="scope">
            <span>{{scope.row[item.prop]| placeholder}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" v-if="releaseForm.tab ==='posting'" fixed="right">
          <template slot-scope="scope">
            <el-button style="color: #3576FF" size="small" type="text"
              @click="handleShowNotUsed(scope.row.id)">不采用
            </el-button>
            <el-button style="color: #3576FF" size="small" type="text"
              @click="toArticleDetail(scope.row.id, scope.row.portal_user_id, scope.row)">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage"></Pagination>
    <NotUsed :id="not_used_id" :showNotUsed="showNotUsed" @closeNotUsed="showNotUsed = false"
      @updateList="updateList"></NotUsed>
    <SetTimePost :showSetTime="showSetTime" :idList="select_id" @closeSetPostTime="showSetTime = false"
      @updateList="updateList"></SetTimePost>
    <ArticleViewer v-model="preview.show" :options="preview.data"></ArticleViewer>
  </div>
</template>

<script>
import { releaseList, postArticle } from "../api/release-list";
import ReleaseScreen from "../components/ReleaseArticle/ReleaseScreen";
import Pagination from "@/base/components/Default/Pagination";
import NotUsed from "../components/ReleaseArticle/NotUsed";
import SetTimePost from "../components/ReleaseArticle/SetTimePost";
import ArticleViewer from "../../../base/components/Preview/ArticleViewer";
import { getPreviewData } from "../api/base";

export default {
  name: "release-article",
  data() {
    return {
      releaseForm: {
        page_size: 15,
        tab: "posting",
        category_id: 0,
        type: -1,
        start_time: -1,
        end_time: -1,
        keyword: "",
        is_desc: 1,
        author_name: "",
        user_id: "",
        user_type: "",
      },
      filters: {
        published: null,
        posting: null,
        rejected: null,
        scheduled: null,
      },
      pageData: {},
      //tab栏
      tabArr: [
        {
          name: "published",
          label: "已发布",
        },
        {
          name: "posting",
          label: "待发布",
        },
        {
          name: "rejected",
          label: "不采用",
        },
        {
          name: "scheduled",
          label: "定时发布",
        },
      ],
      //发布列表
      release_list: [],
      releaseTable: [
        { label: "ID", prop: "id", min_width: 100 },
        { label: "标题", prop: "title", min_width: 200 },
        { label: "来源", prop: "source_name", min_width: 110 },
        { label: "分类", prop: "category_name", min_width: 110 },
        { label: "作者", prop: "author_name", min_width: 110 },
        { label: "状态", prop: "status_text", min_width: 110 },
        { label: "审核人", prop: "checker_name", min_width: 110 },
        {
          label: "创建时间",
          prop: "create_time",
          min_width: 160,
          sortable: true,
        },
      ],
      show_loading: false,
      total: 0,
      select_id: [],
      //不采用弹框
      showNotUsed: false,
      not_used_id: 0,
      //显示定时发布
      showSetTime: false,
      preview: {
        data: {},
        show: false,
      },
    };
  },
  components: {
    ArticleViewer,
    ReleaseScreen,
    Pagination,
    NotUsed,
    SetTimePost,
  },
  watch: {
    "releaseForm.tab"(tabType) {
      if (tabType !== "posting" && tabType !== "rejected") {
        this.releaseTable = [
          { label: "ID", prop: "id", min_width: 100 },
          { label: "标题", prop: "title", min_width: 200 },
          { label: "来源", prop: "source_name", min_width: 110 },
          { label: "分类", prop: "category_name", min_width: 110 },
          { label: "编辑", prop: "author_name", min_width: 110 },
          { label: "状态", prop: "status_text", min_width: 110 },
          { label: "审核人", prop: "checker_name", min_width: 110 },
          {
            label: "创建时间",
            prop: "create_time",
            min_width: 160,
            sortable: true,
          },
          {
            label: "发布时间",
            prop: "post_time",
            min_width: 160,
            sortable: true,
          },
        ];
      } else {
        this.releaseTable = [
          { label: "ID", prop: "id", min_width: 100 },
          { label: "标题", prop: "title", min_width: 200 },
          { label: "来源", prop: "source_name", min_width: 110 },
          { label: "分类", prop: "category_name", min_width: 110 },
          { label: "编辑", prop: "author_name", min_width: 110 },
          { label: "状态", prop: "status_text", min_width: 110 },
          { label: "审核人", prop: "checker_name", min_width: 110 },
          {
            label: "创建时间",
            prop: "create_time",
            min_width: 160,
            sortable: true,
          },
        ];
      }
    },
  },
  created() {
    const requestData = this.$route.query?.tab
      ? { ...this.releaseForm, ...this.$route.query }
      : this.releaseForm;
    this.releaseForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.releaseForm.tab;
    this.getReleaseList(requestData);
  },
  methods: {
    previewArticle(id) {
      this.preview.show = true;
      if (this.preview.data && id !== this.preview.data.id) {
        this.preview.data = {};
        getPreviewData(id)
          .then((res) => {
            this.preview.data = res.data;
          })
          .catch((err) => {});
      }
    },
    sortChange(e) {
      this.releaseForm.order_by = e.prop;
      if (e.order == "ascending") {
        this.releaseForm.is_desc = 0;
      } else if (e.order == "descending") {
        this.releaseForm.is_desc = 1;
      }
      this.refreashList();
    },
    handleShowSetTime() {
      if (this.select_id.length === 0) {
        this.$message({
          message: "请选择你要发布的文章",
          type: "warning",
        });
        return;
      }
      this.showSetTime = true;
    },
    toArticleDetail(id, portal_user_id, row) {
      if (row.article_type) {
        this.$router.push({
          path: "/admin/article-admin/release-article/edit-wx-article",
          query: {
            id: id,
            portal_user_id: portal_user_id,
            come_from: "ArticlePostList",
          },
        });
      } else {
        this.$router.push({
          // path: "/admin/article-admin/release-article/edit-article",
          name: "EditArticle",
          query: {
            id: id,
            portal_user_id: portal_user_id,
            come_from: "ArticlePostList",
          },
        });
      }
    },
    updateList() {
      this.showNotUsed = false;
      this.showSetTime = false;
      this.not_used_id = 0;
      this.select_id = [];
      this.refreashList();
    },
    // 刷新列表
    refreashList() {
      this.getReleaseList({
        ...this.releaseForm,
        page: this.pageData.current_page,
      });
    },
    handleShowNotUsed(id) {
      this.showNotUsed = true;
      this.not_used_id = id;
    },
    handleRelease() {
      if (this.select_id.length === 0) {
        this.$message({
          message: "请选择你要发布的文章",
          type: "warning",
        });
        return;
      }
      this.$confirm("确定要发布该文章？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.show_loading = true;
          postArticle({ id: this.select_id })
            .then(() => {
              this.$message({
                message: "发布成功",
                type: "success",
              });
              this.select_id = [];
              this.refreashList();
            })
            .catch(() => {})
            .finally(() => {
              this.show_loading = false;
            })
        })
        .catch(() => {});
    },
    callbackForm(e) {
      this.releaseForm = e;
      this.getReleaseList(this.releaseForm);
    },
    changPage(e) {
      this.releaseForm.page_size = e.page_size;
      const data = { ...this.releaseForm, page: e.page };
      this.getReleaseList(data);
    },
    handleChangeTab(e) {
      if (!this.filters[e.name]) {
        this.releaseForm = {
          page_size: this.pageData.per_page,
          tab: e.name,
          category_id: 0,
          type: -1,
          start_time: -1,
          end_time: -1,
          keyword: "",
          is_desc: 1,
          author_name: "",
          user_id: "",
          user_type: "",
        };
      } else {
        this.releaseForm = this.filters[e.name];
      }
      this.getReleaseList(this.releaseForm);
    },
    handleSelection(data) {
      let arr = [];
      data.forEach((item, index) => {
        arr.push(item.id);
      });
      this.select_id = arr;
    },
    getReleaseList(requestData) {
      this.release_list = [];
      this.pageData = {};
      this.show_loading = true;
      releaseList(requestData)
        .then((res) => {
          this.release_list = res.data.data;
          this.pageData = res.data;
          this.filters[res.data.tab] = {
            ...this.releaseForm,
            page_size: res.data.per_page,
          };
          this.show_loading = false;
        })
        .catch(() => {
          this.show_loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.reset-page {
}

.content {
  width: 100%;
  box-sizing: border-box;
}

.release-button {
  display: flex;
  align-items: center;
  height: 34px;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
